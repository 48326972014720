import { parseInchesToDecimal } from './parseInches'

declare const acf: any

function getProductProperties() {
    const result = getAcfProperties()
    result.name = getName() as string
    result.imageUrl = getProductImage()?.image?.src ?? ''
    result.url = window.location.href
    const customHeight = document.querySelector<HTMLInputElement>('#custom-height')?.value ?? ''
    const customWidth = document.querySelector<HTMLInputElement>('#custom-width')?.value ?? ''
    const heightErrorEl = document.querySelector<HTMLDivElement>('#custom-height-error')!
    const widthErrorEl = document.querySelector<HTMLDivElement>('#custom-width-error')!
    const customStile = document.querySelector<HTMLInputElement>('#field-stile')?.value ?? ''
    result.custom_stile_value = customStile ? parseFloat(customStile) : ''
    result.custom_stile_id = (window as any).current_stile_id ?? ''

    if (customHeight) {
        try {
            result.custom_height = parseInchesToDecimal(customHeight)
            result.custom_height_text = customHeight
            validateHeight(result.custom_height)
            heightErrorEl.textContent = ''
        } catch (err: any) {
            heightErrorEl.textContent = err.message
            throw err
        }
    }

    if (customWidth) {
        try {
            result.custom_width = parseInchesToDecimal(customWidth)
            result.custom_width_text = customWidth
            validateWidth(result.custom_width)
            widthErrorEl.textContent = ''
        } catch (err: any) {
            widthErrorEl.textContent = err.message
            throw err
        }
    }

    return result
}

function validateHeight(height: number) {
    if (height < 60 || height > 96) {
        throw new Error('Height must be between 60 and 96 inches.')
    }
}

function validateWidth(width: number) {
    if (width < 12 || width > 48) {
        throw new Error('Width must be between 12 and 48 inches.')
    }
}

function getAcfProperties() {
    return acf.getFields().map((x: any) => {
        const select = x.$el[0].querySelector('select')
        const val = x.val()
        let friendlyValue = null
        if (select && select.options.length) {
            friendlyValue = select.options[select.selectedIndex].innerText
        } else {
            const el = document.querySelector('[value="' +  val + '"]')
            if (el && el.parentElement) {
                friendlyValue = el.parentElement.innerText
            }
        }
        if (val == null || friendlyValue == null) return {}
        const fieldName = x.data.name.replaceAll('-', '_')
        return {
            [`${fieldName}_text`]: friendlyValue,
            [fieldName]: val
        }
    }).reduce((prev: any, cur: any) => Object.assign(cur, prev), {})
}

function getName() {
    return document.querySelector('#page_content h1')!.textContent
}


export function getProductImage() {
    const properties = getAcfProperties()
    const images = document.querySelectorAll<HTMLImageElement>('.flex-control-thumbs img').values()
    for (const image of images) {
        const constraints = image.alt.split(';')
        let conditionsMet = true

        if (constraints.length == 0) conditionsMet = false

        for (const param of constraints) {
            const keyValue = param.split(':')
            const key = keyValue[0]
            const value = keyValue[1]
            if (key && value) {
                const range = value.split('-')
                const propValue = parseInt(properties[`${key}_text`].replace('"', ''))
                if (range.length === 1) {
                    if (!(parseInt(range[0]) === propValue)) {
                        conditionsMet = false
                    }
                } else {
                    if (!(parseInt(range[0]) <= propValue && propValue <= parseInt(range[1]))) {
                        conditionsMet = false
                    }
                }
            } else {
                conditionsMet = false
            }
        }

        if (conditionsMet) {
            return {
                image,
                click: true
            }
        }
    }

    // Grab the first image
    return {
        image: document.querySelector<HTMLImageElement>('.woocommerce-product-gallery__image img'),
        click: false
    }
}

export default getProductProperties