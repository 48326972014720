const ERROR_MESSAGE = 'Invalid input, please provide inches in the format: 80 or 80-1/16.'

export function parseInchesToDecimal(inputString: string) {
    const regex = /^(\d+)(?:-(\d+)\/(\d+))?$/
    const match = inputString.match(regex)

    if (!match) {
        throw new Error(ERROR_MESSAGE)
    }

    const wholeInches = parseInt(match[1])
    const numerator = parseInt(match[2])
    const denominator = parseInt(match[3])

    let fractionalInches = 0
    if (!isNaN(numerator) && !isNaN(denominator) && denominator !== 0) {
        if (denominator > 16) {
            throw new Error('Denominator must be equal to or less than 16')
        }
        fractionalInches = numerator / denominator
    }

    if (isNaN(wholeInches) || isNaN(fractionalInches)) {
        throw new Error(ERROR_MESSAGE)
    }

    // Calculate the total inches as a decimal
    const totalInches = wholeInches + fractionalInches

    return totalInches
}