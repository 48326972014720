import getProductProperties, { getProductImage } from './getProductProperties'
import * as authModal from './authModal'
import cookieService from '@/services/cookie-service'
import { getBadge } from './badge'
import { CartIconSvg } from './cartIcon'
import { onReady } from './onReady'
import { customSizeInit } from './customSize'
import { hideGlassGallery } from './glassImageHider'
import { parseInchesToDecimal } from '@/product/parseInches'
import { customStileInit, setStilesForWidth } from './customStiles'
import { setStickyPrice } from './stickyPrice'
import { seriesNoteInit } from './seriesNote'
import { barnTrackInit, isBarnTrack } from './barnTrack'


declare const window: Window & {[key: string]: any}
declare const acf: any

onReady(async () => {
    if (isBarnTrack()) {
        barnTrackInit()
    } else {
        customSizeInit()
        await customStileInit()
        seriesNoteInit()
    }

    function setPriceText(text: string) {
        document.querySelector<HTMLSpanElement>('#door-price')!.innerText = text
    }
    
    async function getPrice() {
        document.getElementById('priceable-section')!.style.display = 'block'
        document.getElementById('pricing-error-section')!.style.display = 'none'
        setPriceText('Loading...')
        document.getElementById('added-message')!.style.display = 'none'
        const item = getProductProperties()
        const params = new URLSearchParams()
        Object.keys(item).forEach(key => params.append(key, item[key]))
        const request = new Request(`${process.env.VUE_APP_API_HOST}/price?${params.toString()}`, {
            method: 'GET',
            credentials: 'include'
        })
        const result = await fetch(request)
        const json = await result.json()
        if (json.quoteRequired) {
            document.getElementById('priceable-section')!.style.display = 'none'
            document.getElementById('pricing-error-section')!.style.display = 'block'
        } else {
            const price = json.quoteRequired ? 'Price Door' : `$${json.price.toFixed(2)}`
            setPriceText(price)
        }
    }

    async function getCartTotalQuantity() {
        const headers = new Headers()
        headers.append('Content-type', 'application/json')
        headers.append('X-XSRF-TOKEN', cookieService.getCookie('XSRF-TOKEN') ?? '')
        const request = new Request(`${process.env.VUE_APP_API_HOST}/cart`, {
            method: 'GET',
            headers,
            credentials: 'include'
        })
        const result = await (await fetch(request)).json()
        const configItems = result?.items?.config
        const webItems = result?.items?.website
        const contractItems = result?.items?.contractPrice
        const allItems = [...configItems, ...webItems, ...contractItems]
        const totalItems = allItems.reduce((prev, cur) => prev + cur.quantity, 0)
        if (acf) {
            addCartName(result)
        }
        return totalItems
    }

    async function addToCart() {
        const item = getProductProperties()
        const headers = new Headers()
        headers.append('Content-type', 'application/json')
        headers.append('X-XSRF-TOKEN', cookieService.getCookie('XSRF-TOKEN') ?? '')
        const quantity = (document.getElementById('item-quantity') as HTMLInputElement).value
        const request = new Request(`${process.env.VUE_APP_API_HOST}/cart/item`, {
            method: 'POST',
            body: JSON.stringify({item, quantity}),
            headers,
            credentials: 'include'
        })
        const result = await fetch(request)
        if (result.status === 403 || result.status === 401) {
            authModal.open(success => {
                if (success) {
                    addToCart()
                    if (cartMenuItem) {
                        updateCartMenuItem(true)
                    }
                }
            })
        } else {
            document.getElementById('added-message')!.style.display = 'block'
        }
    }
    
    const menuItems = document.querySelectorAll('li.menu-item a')
    let cartMenuItem: HTMLAnchorElement | null = null
    menuItems.forEach(elm => {
        const el = elm as HTMLAnchorElement
        if (el.innerText.toLowerCase() === 'cart') {
            cartMenuItem = el
        }
    })
    
    setTimeout(async function () {
        const headers = new Headers()
        headers.append('Content-type', 'application/json')
        headers.append('X-XSRF-TOKEN', cookieService.getCookie('XSRF-TOKEN') ?? '')
        const request = new Request(`${process.env.VUE_APP_API_HOST}/auth/is-auth`, {
            method: 'GET',
            headers,
            credentials: 'include'
        })
        const result = await (await fetch(request)).json()
        if (cartMenuItem) {
            updateCartMenuItem(result.isAuthenticated)
        }
    }, 1)

    async function updateCartMenuItem(authenticated: boolean) {
        const inner = authenticated ? 'Cart' : 'Login'
        let result = ''
        if (authenticated) {
            const count = await getCartTotalQuantity()
            const badge = getBadge(count)
            result = CartIconSvg
            result += '&nbsp;' + badge.outerHTML
            result += '&nbsp;' + inner
        } else {
            result = inner
        }
        cartMenuItem!.innerHTML = result
    }

    async function addCartName(cartObj: any) {
        const addedMessageEl = document.getElementById('added-message')
        const div = document.createElement('div')
        div.innerText = `Current Project: ${cartObj.name}`
        addedMessageEl!.parentElement!.insertBefore(div, addedMessageEl)
    }

    function updateGallery() {
        const image = getProductImage()
        if (image?.click) {
            image?.image?.click()
        }
    }

    // Signature Series will have Wood core and wide edge strip
    function setCoreAndStick() {
        const properties = getProductProperties()
        const widthDropdown = document.getElementById('acf-field_5fe0f2bd6ff5c') as HTMLSelectElement
        
        if (!widthDropdown) return

        const widthDropdownValue = widthDropdown.options[widthDropdown.selectedIndex].innerHTML
        const customWidthElement = document.getElementById('custom-width') as HTMLInputElement
        const width = parseInchesToDecimal(widthDropdownValue.substring(0, widthDropdownValue.length - 1))
        const customWidth = customWidthElement && customWidthElement.value ? parseInchesToDecimal(customWidthElement.value) : 0
        const coreDropdownElement = document.getElementById('acf-field_6304e48ac30e6') as HTMLSelectElement
        const stickDropdownElement = document.getElementById('acf-field_6551ce93999bd') as HTMLSelectElement
        const seriesDropdownElement = document.getElementById('acf-field_6551cf0c999be') as HTMLSelectElement

        let coreDisabled = false
        if (coreDropdownElement && !properties.base_door_text.includes('B')) {
            const isOver = customWidth ? customWidth > 36 : width > 36

            if (isOver) {
                coreDropdownElement.value = '624'
                coreDropdownElement.disabled = true
                coreDisabled = true
            } else {
                coreDropdownElement.disabled = false
                coreDisabled = false
            }
        }

        if (stickDropdownElement && seriesDropdownElement) {
            if (seriesDropdownElement.value == '1343' || seriesDropdownElement.value == '1357') {
                stickDropdownElement.value = '1345'
                stickDropdownElement.disabled = true
                coreDropdownElement.value = '624'
                coreDropdownElement.disabled = true
            } else {
                stickDropdownElement.disabled = false
                coreDropdownElement.disabled = coreDisabled
            }
        }
    }

    //e V-Groove option is only available for the Bladder Pressed Panel Type selection
    function setPanelAdder() {
        const standardOption = document.getElementById('acf-field_6551cf12999bf-1349') as HTMLInputElement
        const vGrooveOption = document.getElementById('acf-field_6551cf12999bf-1347') as HTMLInputElement
        const bladderPressedOption = document.getElementById('acf-field_60b90a7c13d2e-215') as HTMLInputElement

        if (vGrooveOption && standardOption) {
            if (bladderPressedOption && bladderPressedOption.checked) {
                vGrooveOption.disabled = false
            } else {
                standardOption.checked = true
                standardOption.parentElement!.className = 'selected'
                vGrooveOption.checked = false
                vGrooveOption.disabled = true
                vGrooveOption.parentElement!.className = ''
            }
        }
    }

    function onPropertyChange() {
        setStilesForWidth()

        setCoreAndStick()
        setPanelAdder()

        getPrice()
        updateGallery()
    }
    
    window.wpd_addToCart = addToCart
    
    if (window.acf) {
        hideGlassGallery()
        setStickyPrice()
        const productProperties = getProductProperties()
        if (productProperties.not_priceable) {
            document.getElementById('not-priceable-section')!.style.display = 'block'
        } else {
            document.getElementById('priceable-section')!.style.display = 'block'
            acf.getFields().forEach((x: any) => {
                x.on('change', onPropertyChange)
            });
            getPrice()
            updateGallery()
        }
    }
})
