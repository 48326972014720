import cookieService from '@/services/cookie-service'
import getProductProperties from './getProductProperties'

let STILE_DATA: any

async function fetchStiles() {
    const headers = new Headers()
    headers.append('Content-type', 'application/json')
    headers.append('X-XSRF-TOKEN', cookieService.getCookie('XSRF-TOKEN') ?? '')
    const request = new Request(`${process.env.VUE_APP_API_HOST}/website-options/stiles`, {
        method: 'GET',
        headers,
        credentials: 'include'
    })
    return await fetch(request)
}

export async function customStileInit() {
    const properties = getProductProperties()
    const bdt = properties.base_door_text.toLowerCase()
    const bifold = bdt.includes('b') && !bdt.includes('wb') && !bdt.includes('xb') && !bdt.includes('zb')
    if (!bifold && !properties.name.includes('Exterior') && !properties.name.includes('Fire')) {
        appendCustomHtml()
        STILE_DATA = await (await fetchStiles()).json() as any[]
        await setStilesForWidth()
    }
}

function appendCustomHtml() {
    const core = document.querySelectorAll('div[data-name="core"]')
    const selects = document.querySelectorAll('.acf-fields select')
    let lastSelectParent: Element | null | undefined
    if (core && core.length) {
        lastSelectParent = core[core.length - 1]
    } else {
        lastSelectParent = selects[selects.length - 1].parentElement?.parentElement
    }

    const html = `
    <div class="acf-field acf-field-select" style="width: 33.3%; min-height: 91px;" data-name="stile" data-type="select" data-key="field_stile" data-width="33.3">
        <div class="acf-label">
            <label for="field-stile">Stiles</label></div>
        <div class="acf-input">
            <select id="field-stile" class="" name="acf[field_6304e48ac30e6]" data-ui="0" data-ajax="0" data-multiple="0" data-placeholder="Select" data-allow_null="0"></select>
        </div>
    </div>
`

    lastSelectParent?.after(document.createRange().createContextualFragment(html))
}

export function setStilesForWidth() {
    const stileData = STILE_DATA
    const stileSelect = document.querySelector<HTMLSelectElement>('select#field-stile')
    if (!stileData || !stileSelect) return

    const properties = getProductProperties()
    let width = Math.floor(parseFloat(properties.custom_width || properties.width_text.replace('"', '')))

    let stilesForWidth: any
    while (!stilesForWidth && width <= 48) {
        stilesForWidth = stileData.find((x: any) => x.doorWidth === width)
        width++
    }

    (window as any).current_stile_id = stilesForWidth.id

    const selectedValue = stileSelect.value
    const standard = document.createElement('option')
    standard.innerText = stilesForWidth.standardWidthDisplay
    standard.value = stilesForWidth.standardWidthValue
    standard.selected = standard.value === selectedValue
    stileSelect.options.length = 0
    stileSelect.options.add(standard)

    let additional: HTMLOptionElement | null = null
    if (stilesForWidth.additionalWidthValue) {
        additional = document.createElement('option')
        additional.innerText = stilesForWidth.additionalWidthDisplay
        additional.value = stilesForWidth.additionalWidthValue
        additional.selected = additional.value === selectedValue
        stileSelect?.options.add(additional)
    }

    if (!standard.selected && !additional?.selected) {
        standard.selected = true
    }
}
