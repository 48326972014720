import cookieService from '@/services/cookie-service'
import getProductProperties from './getProductProperties'

export function seriesNoteInit() {
    appendCustomHtml()
}

function appendCustomHtml() {
    const series = document.querySelectorAll('div[data-name="series"]')
    const seriesNote = document.querySelector('div[data-name="series-note"]')
    const selects = document.querySelectorAll('.acf-fields select')
    let lastSelectParent: Element | null | undefined
    if (series && series.length) {
        lastSelectParent = series[series.length - 1]
    } else {
        lastSelectParent = selects[selects.length - 1].parentElement?.parentElement
    }

    if (!seriesNote) {
        return
    }

    const seriesNoteOptions = Array.from(seriesNote.querySelectorAll('li label'))

    const html = `
    <div class="acf-field" style="width: 33.3%; min-height: 91px;" data-width="33.3">
        <div class="acf-label"><label>Series Notes</label></div>
        ${seriesNoteOptions.map(x => {
            const parts = x.textContent!.split(' - ')
            return `<div style="font-size:14px"><b>${parts[0]}</b> - ${parts[1]}</div>`
        }).join('')}
    </div>
`

    lastSelectParent?.after(document.createRange().createContextualFragment(html))
}
