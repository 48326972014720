const cartBadgeId = 'cart-badge-quantity'
export function getBadge(badgeValue: string) {
    const badge = document.createElement('span')
    badge.id = cartBadgeId
    badge.style.backgroundColor = '#f44336'
    badge.style.color = 'white'
    badge.style.padding = '4px'
    badge.style.borderRadius = '10px'
    badge.style.top = '2px'
    badge.style.fontSize = '0.7em'
    badge.style.position = 'relative'
    badge.style.top = '-20px'
    badge.style.left = '-12px'
    badge.innerText = badgeValue
    return badge
}

export function updateBadge(badgeValue: string) {
    const badge = document.getElementById(cartBadgeId)
    badge!.innerText = badgeValue
}