import cookieService from '@/services/cookie-service'

declare const window: Window & any
declare const acf: any

const modal = document.createElement('div')
modal.style.cssText = `
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);`

const modalContent = document.createElement('div')
modalContent.style.cssText = `
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px; 
    border: 1px solid #888;
    width: 450px;
    max-width: 80%;`

const modalClose = document.createElement('a')
modalClose.href = 'javascript:void 0;'
modalClose.innerHTML = '<span>&times;</span>'
modalClose.style.cssText = `
    text-decoration: none;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;`

const modalForm = document.createElement('div')
modalForm.style.textAlign = 'center'
modalForm.innerHTML = `
    <div><label for="username">Username:</label> <input id="username" /></div>
    <br/>
    <div><label for="password">Password:</label> <input type="password" id="password" /></div>
    <br/>
    <div id="login-error" style="display: none;margin-bottom:15px;">
        Username or password is incorrect
    </div>
    <button type="button">Login</button>`

modalContent.appendChild(modalClose)
modalContent.appendChild(modalForm)
modal.appendChild(modalContent)

const button = modalForm.querySelector('button')!

button.onclick = async function () {
    button.disabled = true
    const username = modalForm.querySelector<HTMLInputElement>('#username')!.value
    const password = modalForm.querySelector<HTMLInputElement>('#password')!.value
    const headers = new Headers()
    headers.append('Content-type', 'application/json')
    headers.append('X-XSRF-TOKEN', cookieService.getCookie('XSRF-TOKEN') ?? '')

    const request = new Request(`${process.env.VUE_APP_API_HOST}/auth/login`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username, password }),
        credentials: 'include'
    })
    const result = await fetch(request)
    if (result.status === 200) {
        close(true)
    } else {
        modalForm.querySelector<HTMLDivElement>('#login-error')!.style.display = 'block'
    }
    button.disabled = false
}

let modalCallback: ((success: boolean) => void) | undefined = undefined

modalClose.onclick = function() {
    close(false)
}

window.onclick = function(event: Event) {
    if (event.target == modal) {
        close(false)
    }
}

document.querySelector('body')!.appendChild(modal)

export function open(cb?: (success: boolean) => void) {
    button.disabled = false
    modal.style.display = 'block'
    modalCallback = cb
}

export function close(success: boolean) {
    modal.style.display = 'none'
    if (typeof modalCallback === 'function') modalCallback(success)
    modalCallback = undefined
}
