
export function setStickyPrice(timeout = 0) {
  setTimeout(() => {
    const el = document.getElementById('priceable-section')
    if (!el) {
      setStickyPrice(10)
    } else {
      let isSticky = false
      const rect = el.getBoundingClientRect()
      let startingPosition = rect.y + window.pageYOffset
      addEventListener('scroll', () => {
        const rect = el!.getBoundingClientRect()
        const offScreen = (rect.y) < 0
        if (!isSticky && offScreen) {
          isSticky = true
          el!.style.position = 'fixed'
          el!.style.top = '0'
        } else if (isSticky && window.pageYOffset < startingPosition) {
          isSticky = false
          el!.style.position = 'relative'
          el!.style.top = 'initial'
        }

        if (!isSticky) {
          startingPosition = el.getBoundingClientRect().y + window.pageYOffset
        }
      })
    }
  }, timeout)
}