export function customSizeInit() {
    const heightEl = document.querySelector<HTMLDivElement>('div[data-name="height"]')!
    const widthEl = document.querySelector<HTMLDivElement>('div[data-name="width"]')!

    appendCustomHtml(heightEl, 'custom-height', 'Custom Height (e.g. 80-1/16)')
    appendCustomHtml(widthEl, 'custom-width', 'Custom Width (e.g. 26-1/16)')
}

function appendCustomHtml(el: HTMLDivElement, inputId: string, placeholder: string) {
    if (!el) return
    const html = `<div style="text-align: center;">or</div> <input type="text" id="${inputId}" placeholder="${placeholder}" autocomplete="one-time-code"><div id="${inputId}-error"></div>`
    el.innerHTML = el.innerHTML + html
}