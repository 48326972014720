
function getFileName(src: string) {
    return src?.split("/")?.pop()?.split(".")[0] ?? ''
}

export function hideGlassGallery(timeout = 0) {
    setTimeout(() => {
        const nodes =  document.querySelectorAll('.woocommerce-product-gallery li')
        if (!nodes.length) {
            hideGlassGallery(10)
        } else {
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i]
                const img = node.querySelector('img')
                const glassImages = Array.from(document.querySelectorAll<HTMLImageElement>('.acf-field[data-name="glass"] img'))
                    .map(x => getFileName(x.src))
                if (!img || !glassImages.length) continue
                for (const src of glassImages) {
                    if (src.includes(getFileName(img?.src))) {
                        img.parentElement!.style.display = 'none'
                    }
                }
            }
        }
    }, timeout)
}